import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const SocialIcon = (props) => {

  let icon = '';
  let bgColor = {};

  switch(props.icon){

    case 'facebook':
    icon = (<svg version="1.1" baseProfile="basic" id="Layer_1"	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.7 56.7"><path d="M39.3,22.6h-6.8v-4.5c0-1.7,1.1-2.1,1.9-2.1c0.8,0,4.8,0,4.8,0V8.6l-6.7,0c-7.4,0-9.1,5.5-9.1,9.1v4.9h-4.3v7.6h4.3	c0,9.8,0,21.6,0,21.6h9c0,0,0-11.9,0-21.6h6.1L39.3,22.6z"/></svg>);
    bgColor = {backgroundColor:'#3b5998'};
    break;

    case 'instagram':
    icon = (<svg version="1.1" baseProfile="basic" id="Layer_1"	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.7 56.7"><g>	<path d="M28.2,18.8c-5.8,0-10.7,4.8-10.7,10.7s4.8,10.7,10.7,10.7s10.7-4.8,10.7-10.7S34,18.8,28.2,18.8z M28.2,36.4		c-3.8,0-6.8-3.1-6.8-6.8c0-3.8,3.1-6.8,6.8-6.8s6.8,3.1,6.8,6.8C35,33.3,32,36.4,28.2,36.4z"/>	<circle cx="39.3" cy="18.6" r="2.4"/>	<path d="M45.6,12.3c-2.2-2.3-5.3-3.4-8.8-3.4H19.6C12.3,8.9,7.5,13.7,7.5,21v17.1c0,3.6,1.2,6.7,3.5,8.9c2.3,2.2,5.3,3.3,8.7,3.3		h17c3.6,0,6.6-1.2,8.8-3.3c2.3-2.2,3.4-5.3,3.4-8.8V21C48.9,17.5,47.7,14.5,45.6,12.3z M45.2,38.2c0,2.6-0.9,4.7-2.4,6.1		s-3.6,2.2-6.1,2.2h-17c-2.5,0-4.6-0.8-6.1-2.2c-1.5-1.5-2.3-3.6-2.3-6.2V21c0-2.5,0.8-4.6,2.3-6.1c1.4-1.4,3.6-2.2,6.1-2.2h17.2		c2.5,0,4.6,0.8,6.1,2.3c1.4,1.5,2.3,3.6,2.3,6V38.2L45.2,38.2z"/></g></svg>);
    break;

    case 'linkedin':
    icon = (<svg version="1.1" baseProfile="basic" id="Layer_1"	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.7 56.7"	><g>	<path d="M32.1,24.9v-0.1C32.1,24.8,32.1,24.8,32.1,24.9L32.1,24.9z"/>	<path d="M16.7,50.3H6.8V20.6h9.9V50.3z M11.8,16.5L11.8,16.5c-3.4,0-5.5-2.3-5.5-5.1c0-2.9,2.2-5.1,5.6-5.1c3.4,0,5.5,2.2,5.5,5.1		C17.4,14.2,15.2,16.5,11.8,16.5z M52.4,50.3h-9.9V34.4c0-4-1.4-6.7-5-6.7c-2.7,0-4.4,1.8-5.1,3.6c-0.3,0.6-0.3,1.5-0.3,2.4v16.6		h-9.9c0,0,0.1-27,0-29.8h9.9v4.2c1.3-2,3.7-4.9,8.9-4.9c6.5,0,11.4,4.3,11.4,13.4L52.4,50.3L52.4,50.3z"/></g></svg>);
    bgColor = {backgroundColor:'rgb(10, 102, 194)'};
    break;

    case 'twitter':
    icon = (<svg version="1.1" baseProfile="basic" id="Layer_1"	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 56.7 56.7"><path d="M47.7,19c0,0.4,0,0.8,0,1.2C47.7,32.6,38.3,47,21,47c-5.3,0-10.3-1.6-14.4-4.2c0.7,0.1,1.5,0.1,2.2,0.1	c4.4,0,8.5-1.5,11.7-4c-4.1-0.1-7.6-2.8-8.8-6.5c0.6,0.1,1.2,0.2,1.8,0.2c0.9,0,1.7-0.1,2.5-0.3c-4.3-0.9-7.5-4.7-7.5-9.2	c0,0,0-0.1,0-0.1c1.3,0.7,2.7,1.1,4.3,1.2c-2.5-1.7-4.2-4.6-4.2-7.8c0-1.7,0.5-3.3,1.3-4.7c4.6,5.7,11.6,9.4,19.4,9.8	c-0.2-0.7-0.2-1.4-0.2-2.1c0-5.2,4.2-9.4,9.4-9.4c2.7,0,5.1,1.1,6.9,3c2.1-0.4,4.2-1.2,6-2.3c-0.7,2.2-2.2,4-4.1,5.2	c1.9-0.2,3.7-0.7,5.4-1.5C51.2,16,49.6,17.7,47.7,19z"/></svg>);
    bgColor = {backgroundColor:'#00aced'};
    break;

    default:
    icon = (<svg></svg>);

  }

  if (!props.share) {

    return(
      <a href={props.url} className="socialicon">
      {icon}
      </a>
    )

  } else {

    return(
      <div>
      <a target="_blank" href={props.url} className="socialshare" style={bgColor}>
      <span  className=" icon">{icon}</span>
      <span className=" text">{props.share}</span>
      </a>
      </div>
    )

  }


}

export default SocialIcon
