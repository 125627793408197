import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Logo = ({ size }) => (

  <svg version="1.1" id={size} x="0px" y="0px" viewBox="0 0 1000 1000" xml-space="preserve">
  <g id="journey">
    <g>
      <path d="M45.5,938.1c3.7-11.8,4.1-23.4,4.1-54.2v-90.1l-20.7-2.5v-8.9h57.8v8.9l-19.9,2.5v85.5c0,23,0.4,39.8-2.1,52.6
        C60,955.7,40.3,968.7,22.5,972l-4.6-7.5C30.2,961.3,41.2,951.9,45.5,938.1z"/>
      <path d="M148.6,818.2c37.1,0,51.1,28,51.1,60.5c0,37.7-26.7,59.8-53.2,59.8c-37.1,0-51.1-28-51.1-60.5
        C95.4,840.3,122.1,818.2,148.6,818.2z M180.9,879.9c0-27.3-8.9-50.9-33.1-50.9c-21.1,0-33.5,16.2-33.5,47.8
        c0,27.7,9.3,50.9,33.3,50.9C168.5,927.7,180.9,911.4,180.9,879.9z"/>
      <path d="M300,929.4v-8.1c-8.3,7.5-23.4,17.2-38.7,17.2c-25.5,0-34.8-13.5-34.8-44.9v-60.9l-12.2-3.5v-7.7l25.1-2.7h0.2l3.7,2.5
        v69.8c0,24.6,3.9,34.8,23.2,34.8c13.5,0,25.3-7.7,31.7-13.3v-79.9l-15.7-3.5v-7.7l27.5-2.7h0.2l4.8,2.5v105.8h12.8l-0.2,7.9
        c-4.6,1.2-9.9,3.1-17.2,3.1C303.7,938.1,300,936.4,300,929.4z"/>
      <path d="M344.7,927.5l16.6-1.2V835l-15.9-3.5v-9.3l26.3-3.3h0.4l3.9,3.3v3.3l-0.4,18.4h0.4c2.5-2.7,18-23,40.8-23
        c2.9,0,4.8,0.4,6.2,0.8v17.4c-0.8-0.6-5-1.9-9.5-1.9c-17.8,0-29,8.3-35.4,13v75.8l27.5,1.7v8.5h-60.9V927.5z"/>
      <path d="M453.6,835l-15.9-3.5v-9.3l26.3-3.3h0.6l3.9,3.3v9.1l-0.2,5.2c9.1-7.9,28.2-17.8,43.7-17.8c26.9,0,33.1,15.3,33.1,48.7
        v58.6l15.5,1.5v8.9h-46.2v-8.9l13.9-1.5V867c0-24-3.3-35.6-23.4-35.6c-11.8,0-26.1,7.2-34.6,13.9v80.3l15.1,1.7v8.9h-45.8v-8.9
        l13.9-1.7V835z"/>
      <path d="M572.7,878.2c0-35.6,22.6-60,53-60c24.4,0,38.7,14.3,39.8,41c0,7.9-0.2,13.7-1.2,18.2h-73.7c0,28,12.8,49.3,37.5,49.3
        c11.8,0,25.5-5,31.9-10.6l3.3,7.7c-7.9,8.1-24.6,14.7-40.6,14.7C589.9,938.5,572.7,913.6,572.7,878.2z M646.8,867.7
        c0.4-2.9,0.6-6.2,0.6-9.1c-0.2-17-7.5-29.8-25.9-29.8c-16.6,0-29,10.1-30.9,38.9H646.8z"/>
      <path d="M689.5,986.3V971c1.7,0.8,6.8,1.2,10.6,1.2c11,0,22.2-4.1,33.5-36h-9.1l-39.5-103.7l-9.7-2.7v-8.5H721v8.5l-16.8,2.5
        l21.7,63.4l10.8,33.8l10.4-34l19.5-63.2l-14.5-2.5v-8.5h39.8v8.5l-10.8,2.5c-24.6,75.6-32.5,99.2-36,107.3
        c-12.8,31.9-21.3,48.2-45.3,48.2C695.9,987.8,691.1,987.1,689.5,986.3z"/>
    </g>
  </g>
  <g id="financial">
    <g>
      <path d="M42.6,588.5l-17-2.7V573h114.3l2.1,37.5h-16.6l-7.7-22.8L73,585.4V644l30.9-0.6l3.9-18.2h13.3v52.6h-13l-4.1-18.6
        L73,658.3v54l31.7,2.1v12.4H26.7v-12.4l15.9-2.1V588.5z"/>
      <path d="M169.5,631.4l-14.5-3.9v-13.9l37.7-4.6h0.4l5.8,4.1v99.6l14.1,1.7v12.4h-58v-12.4l14.5-1.9V631.4z M164.4,577.3
        c0-10.4,6.8-18.2,18.2-18.2c11.8,0,17.2,7.2,17.2,15.5c0,10.8-6.6,18.2-19,18.2h-0.2C170.2,592.9,164.4,586,164.4,577.3z"/>
      <path d="M238.5,631.4l-13.3-3.7v-14.3l34.6-4.3h0.6l5,4.1v8.1l-0.2,5c8.7-8.1,25.5-16.8,42.2-16.8c25.1,0,33.3,13,33.3,46.2v57.1
        l12.6,1.7v12.4h-53.6v-12.4l11.2-1.7v-57.1c0-20.5-3.1-28.6-17.6-28.6c-9.7,0-19.5,5.8-25.3,10.1v75.4l12.4,1.9v12.4h-54v-12.4
        l12-1.9V631.4z"/>
      <path d="M431.1,656.4v-4.8c0-15.9-3.7-22.8-21.5-22.8c-15.9,0-28.4,6.2-35,9.3l-6.4-13.3c5-4.1,25.3-15.7,48.9-15.7
        c31.5,0,41.6,11.8,41.6,41.8v63.4H470V726c-4.8,1.2-17.2,3.1-25.5,3.1c-9.3,0-11.8-1.9-11.8-10.6V713c-5.2,6-17.4,16.2-34.6,16.2
        c-18.8,0-34.8-12-34.8-34.6C363.4,668.2,393.6,656.6,431.1,656.4z M431.1,703v-34c-26.1,0-36.9,10.1-36.9,22.6
        c0,13.5,7,20.3,17.2,20.3C417.4,711.9,425.7,707.8,431.1,703z"/>
      <path d="M496.1,631.4l-13.3-3.7v-14.3l34.6-4.3h0.6l5,4.1v8.1l-0.2,5c8.7-8.1,25.5-16.8,42.2-16.8c25.1,0,33.3,13,33.3,46.2v57.1
        l12.6,1.7v12.4h-53.6v-12.4l11.2-1.7v-57.1c0-20.5-3.1-28.6-17.6-28.6c-9.7,0-19.5,5.8-25.3,10.1v75.4l12.4,1.9v12.4h-54v-12.4
        l12-1.9V631.4z"/>
      <path d="M679.8,608.8c17.2,0,27.3,5,33.5,6.2l-1.2,35.4h-18l-5-22.8c-1-4.1-6.8-5.4-12.4-5.4c-14.5,0-24.4,11.8-24.6,41.8
        c-0.2,31.3,11.6,47,28.8,47c12.8,0,22.4-4.3,28.8-8.5l5,10.8c-5.8,6.2-21.5,15.7-40.4,15.7c-36.7,0-53.2-25.3-53.4-58.8
        C620.7,634.3,643.3,608.8,679.8,608.8z"/>
      <path d="M745.6,631.4l-14.5-3.9v-13.9l37.7-4.6h0.4l5.8,4.1v99.6l14.1,1.7v12.4h-58v-12.4l14.5-1.9V631.4z M740.4,577.3
        c0-10.4,6.8-18.2,18.2-18.2c11.8,0,17.2,7.2,17.2,15.5c0,10.8-6.6,18.2-19,18.2h-0.2C746.2,592.9,740.4,586,740.4,577.3z"/>
      <path d="M867.1,656.4v-4.8c0-15.9-3.7-22.8-21.5-22.8c-15.9,0-28.4,6.2-35,9.3l-6.4-13.3c5-4.1,25.3-15.7,48.9-15.7
        c31.5,0,41.6,11.8,41.6,41.8v63.4h11.4V726c-4.8,1.2-17.2,3.1-25.5,3.1c-9.3,0-11.8-1.9-11.8-10.6V713c-5.2,6-17.4,16.2-34.6,16.2
        c-18.8,0-34.8-12-34.8-34.6C799.4,668.2,829.7,656.6,867.1,656.4z M867.1,703v-34c-26.1,0-36.9,10.1-36.9,22.6
        c0,13.5,7,20.3,17.2,20.3C853.5,711.9,861.8,707.8,867.1,703z"/>
      <path d="M930.7,575.5l-15.3-2.7v-11.2l38.3-4.6h0.4l5.4,3.9v151.8l15.3,1.7v12.4h-58.6v-12.4l14.5-1.9V575.5z"/>
    </g>
  </g>
  <g id="her">
    <g>
      <path d="M47,374.8l-17-2.3v-8.9h52v8.9l-18,2.3V433H143v-58.2l-17.4-2.3v-8.9h50.7v8.9l-16.4,2.3v131.9l17.2,1.9v8.9h-51.8v-8.9
        l17.6-1.9V444H63.9v62.7l18.2,1.9v8.9H29.8v-8.9l17.2-1.9V374.8z"/>
      <path d="M189.8,459.5c0-35.6,22.6-60,53-60c24.4,0,38.7,14.3,39.8,41c0,7.9-0.2,13.7-1.2,18.2h-73.7c0,28,12.8,49.3,37.5,49.3
        c11.8,0,25.5-5,31.9-10.6l3.3,7.7c-7.9,8.1-24.6,14.7-40.6,14.7C207,519.8,189.8,494.9,189.8,459.5z M264,448.9
        c0.4-2.9,0.6-6.2,0.6-9.1c-0.2-17-7.5-29.8-25.9-29.8c-16.6,0-29,10.1-30.9,38.9H264z"/>
      <path d="M299.8,508.8l16.6-1.2v-91.3l-15.9-3.5v-9.3l26.3-3.3h0.4l3.9,3.3v3.3l-0.4,18.4h0.4c2.5-2.7,18-23,40.8-23
        c2.9,0,4.8,0.4,6.2,0.8v17.4c-0.8-0.6-5-1.9-9.5-1.9c-17.8,0-29,8.3-35.4,13v75.8l27.5,1.7v8.5h-60.9V508.8z"/>
    </g>
  </g>
  <g id="shapes">
    <g>

        <polyline class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
        527.6,168.9 364.8,19.2 365.7,276.2 527.6,168.9 367.8,170 		"/>
      <g>

          <rect class="shape_stroke" x="26.2" y="19" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="257" height="257"/>

          <line class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="26.2" y1="147.5" x2="283.2" y2="147.5"/>
      </g>
      <g>

          <path class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="11.1126" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
          M822.2,96.2"/>

          <polygon class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="11" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
          731.5,20.1 731.5,158.9 802.3,86.1 			"/>

          <polygon class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="11" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
          794.9,217.3 731.4,158.9 853.5,273 853.5,158.3 			"/>

          <polyline class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="11" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
          801,86.4 731.8,158.3 795.1,217 851.9,157.8 985.3,19.5 867,19.5 801,86.4 			"/>

          <polygon class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
          728.9,20.1 728.9,79.4 674.2,79.4 			"/>
      </g>

        <line class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="867" y1="21.9" x2="795.1" y2="217.3"/>
    </g>

      <polyline class="shape_stroke" fill="none" stroke="#F0604C" stroke-width="12.3032" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
      365.2,19 622.2,19 502.4,138.8 	"/>
  </g>
  </svg>

)

Logo.propTypes = {
  size: PropTypes.string,
}

Logo.defaultProps = {
  size: `hfj_logo_header`,
}

export default Logo
