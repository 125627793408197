import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SocialIcon from './socialicon'

const Footer = () => (
  <footer>

    <div class="section footer">
      <div class="container">
        <div class="row">


        <div class="five columns">
          <span class="footerlink"><Link to="/blog">Blog</Link></span>
          <span class="footerlink"><Link to="/about">About</Link></span>
          <span class="footerlink"><Link to="/privacy">Privacy</Link></span>
        </div>

            <div class="four columns socialcontainer">
              <SocialIcon icon="facebook" url="https://facebook.com/herfinancialjourney"/>
              <SocialIcon icon="twitter" url="https://twitter.com/herfinancial"/>
              <SocialIcon icon="instagram" url="https://instagram.com/herfinancialjrny"/>
              <SocialIcon icon="linkedin" url="https://www.linkedin.com/company/her-financial-journey"/>
            </div>


            <div class="three columns">
              <span class="footercopy">&copy; {new Date().getFullYear()} Her Financial Journey Pty Ltd</span>
            </div>

        </div>
      </div>
    </div>

  </footer>
)


export default Footer
